import React, { useState, useEffect } from "react";
import { Paper, Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import arrow from "../images/arrow.png";

const useStyles = makeStyles({
  box: {
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "auto",
  },
  paper: {
    backgroundColor: "blue",
    borderRadius: 20,
    borderColor: "#000",
    padding: 50,
    width: "30%",
  },
  paperText: {
    color: "white",
  },
});

export default function PlacementInfo() {
  /*

  */
  const classes = useStyles();
  return (
    <Box
      className={classes.box}
      sx={{ display: "inline-block", backgroundImage: `url(${arrow})` }}
    >
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Paper className={classes.paper} sx={{ backgroundColor: "blue" }}>
            <h4 className={classes.paperText}>1. Pre-Placement</h4>
            <ul className={classes.paperText}>
              <li>Personal Training Plan & WP</li>
              <li>Engage with Host Organisation</li>
              <li>Formalise Contracts</li>
              <li>Register module if available (ECTS)</li>
            </ul>
          </Paper>
        </Grid>
        <Paper className={classes.paper} sx={{ backgroundColor: "blue" }}>
          <h4 className={classes.paperText}>1. Pre-Placement</h4>
          <ul className={classes.paperText}>
            <li>Personal Training Plan & WP</li>
            <li>Engage with Host Organisation</li>
            <li>Formalise Contracts</li>
            <li>Register module if available (ECTS)</li>
          </ul>
        </Paper>
      </Grid>
    </Box>
  );
}
