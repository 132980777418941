import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import TestPage from "./pages/testPage";
import TestProfilePage from "./pages/testProfilePage";
import PeoplePage from "./pages/peoplePage";
import AboutPage from "./pages/aboutPage";
import EDIStatementPage from "./pages/ediStatementPage";
import ResearchProgrammePage from "./pages/researchProgrammePage";
import TrainingProgrammePage from "./pages/trainingProgrammePage";
import PlacementProgrammePage from "./pages/placementProgrammePage";
import PreviousEventsPage from "./pages/previousEventsPage";
import ContactPage from "./pages/contactPage";
import PartnershipPage from "./pages/partnershipPage";
import PosterPage from "./pages/posterPage";
import ProfilePage from "./pages/profilePage";
import NewsletterPage from "./pages/newsletterPage";
import StudentResourcesPage from "./pages/resourcesPage";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#00008B",
    },
    secondary: {
      main: "#00283F",
    },
    text: {
      primary: "#413E77",
      //secondary: "#05C78D",
      disabled: "#06DB9B",
    },
    background: {
      paper: "#ffffff",
      default: "#f5f5f5",
    },
  },
  typography: {
    h2: {
      color: "#ffffff",
      textTransform: "capitalize",
      fontFamily: `"Open Sans", "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif`,
    },
    h3: {
      color: "#0D3B66",
      fontFamily: `"Open Sans", "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif`,
    },
    subtitle1: {
      color: "#0D3B66",
      fontSize: 25,
      fontFamily: `"Open Sans", "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif`,
    },
    subtitle2: {
      color: "#00008B",
      fontSize: 20,
      fontStyle: "oblique",
      fontFamily: `"Open Sans", "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif`,
    },
    body1: {
      color: "#262262",
      fontSize: 18,
      fontFamily: `"Open Sans", "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif`,
    },
    button: {
      color: "#262262",
      fontSize: 18,
    },
  },
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    salmon: theme.palette.augmentColor({
      color: {
        main: "#FF5733",
      },
      name: "salmon",
    }),
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  return (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route exact path="/test" element={<TestPage />} />
          <Route exact path="/test/:id" element={<TestProfilePage />} />
          <Route exact path="/people" element={<PeoplePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/edi" element={<EDIStatementPage />} />
          <Route exact path="/training" element={<TrainingProgrammePage />} />
          <Route exact path="/research" element={<ResearchProgrammePage />} />
          <Route exact path="/placement" element={<PlacementProgrammePage />} />
          <Route exact path="/resources" element={<StudentResourcesPage />} />

          <Route
            exact
            path="/previousevents"
            element={<PreviousEventsPage />}
          />
          <Route exact path="/contactus" element={<ContactPage />} />
          <Route exact path="/partnerships" element={<PartnershipPage />} />
          <Route exact path="/posters" element={<PosterPage />} />
          <Route exact path="/newsletters" element={<NewsletterPage />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/people/:id" element={<ProfilePage />} />
        </Routes>
      </ThemeProvider>
    </HashRouter>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
