import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import { List, ListItem } from "@mui/material";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import PlacementInfo from "../components/placementinfo";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
  list: {
    display: "inline-block",
    listStyle: "",
  },
});

const StudentResourcesPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "Student Resources";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="h2" color="#0D3B66">
              Placment with ADVANCE CRT
            </Typography>
            <Typography>
              ADVANCE CRT Researchers are expected to partake in a 3 to 6 month
              placement as part of their PhD. This placement is a{" "}
              <span style={{ color: "red" }}>MANDATORY</span> part of the
              ADVANCE CRT programme.
            </Typography>
            <br />
            <Typography>
              It is the{" "}
              <span style={{ fontWeight: "bold" }}>
                responsibility of the supervisor
              </span>{" "}
              to arrange this placement, with proactive support from the PhD
              researcher.
            </Typography>
            <Typography>Types of work placement:</Typography>
            <List
              className={classes.list}
              sx={{ listStyleType: "disc", pl: 2 }}
            >
              <ListItem>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>
                    Aligned with research
                  </span>{" "}
                  or{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Not aligned with research
                  </span>{" "}
                  - Decided by supervisor and PhD researcher
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>
                    Paid by host organisation
                  </span>{" "}
                  or{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Unpaid by host organisation
                  </span>{" "}
                  – Documentation obligations vary{" "}
                </Typography>
              </ListItem>
            </List>
            <Typography>Types of Organisation:</Typography>
            <List
              className={classes.list}
              sx={{ listStyleType: "disc", pl: 2 }}
            >
              <ListItem>
                <Typography>
                  Industry organisation (Multinational, SME, etc…)
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>Civil society organisation</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  International research organisation (University or Research
                  Centre outside Rep. of Ireland)
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>Public bodies</Typography>
              </ListItem>
            </List>
            <Typography>
              Placements can be{" "}
              <span style={{ fontWeight: "bold" }}>
                in-person, remote, or hybrid.
              </span>
            </Typography>
            <br />
            <Typography variant="h4" color="#0D3B66">
              The Placement Process
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default StudentResourcesPage;
